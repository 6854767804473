html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  background: #000;
}
#root {
  height: 100%;
}
.App {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-image: url("assets/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.bg-img {
  height: 100%;
}
.card {
  background: rgba(0, 0, 0, 0.5);
  padding: 50px 20px;
  width: 100%;
}
.logo {
  width: 200px;
}
h1 {
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
}
footer {
  background: rgba(0, 0, 0, 0.5);
  padding: 0px 20px 42px 20px;
  position: relative;
}
@media screen and (max-width: 768px) {
  footer div.flexbox {
    flex-direction: column;
  }
}
.powered-by {
  background: #000;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 12px 20px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 1px;
}
a {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}
p {
  color: #fff;
  margin: 0;
  padding: 12px 20px;
}
.slideInDown {
  animation-name: slideInDown;
  animation-duration: 1.5s;
  animation-fill-mode: both;
}
.flexbox {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-height: 700px) {
  .card {
    padding: 20px;
  }
}
@keyframes slideInDown {
  0% {
    transform: translateY(-100%);
    visibility: visible;
  }
  100% {
    transform: translateY(0);
  }
}
.small-icon {
  width: 20px;
  margin-right: 12px;
}
